/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Bootstrap grid */
@import "assets/css/bootstrap-grid.css";
@import "assets/css/bootstrap-utilities.css";

/* Variable */
$fontsize: 40px;
$fontsizeHeading1: 3em; //42px
$fontsizeHeading2: 1.8em; //26px
$fontsizeHeading3: 1.5em; //21px
$fontsizeHeading4: 1.3em; //18px
$fontsizeHeading5: 1.1em; //15px
$fontsizeHeading6: 1em;
$fontsizeParagraph: 1em;
$fontsizeParagraphsmall: 0.9em; //13px
$fontsizeParagraphxsmall: 0.8em; //11px
$fontsizeParagraphxxsmall: 0.7em; //10px

:root {
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;

  ion-header,
  ion-footer {
    background: #fff;
    color: var(--ion-color-dark);
  }

  ion-toolbar {
    --min-height: 60px;
    --padding-start: 0;
    --padding-end: 0;
    ion-title {
      text-align: center;
      font-size: $fontsizeHeading5;
      font-weight: 600;
    }
    ion-buttons {
      margin: auto;
    }
  }
  ion-back-button {
    --icon-font-size: 24px;
    --min-width: 36px;
    --min-height: 36px;
    --icon-margin-top: 0;
    --icon-margin-bottom: 0;
    --icon-margin-start: 0;
    --icon-margin-end: 0;
    --margin-top: 0;
    --margin-bottom: 0;
    --margin-start: 0;
    --margin-end: 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background: #fff;
  }

  ion-searchbar {
    height: 37px;
    padding-bottom: 0;
    --background: #fff;
    --border-radius: 18px;
    .searchbar-input {
      padding-inline-start: 40px !important;
      border: 1px solid rgba(var(--ion-color-dark-rgb), 0.1);
      font-size: $fontsize !important;
    }
    .searchbar-search-icon {
      left: 12px;
    }
  }

  ion-chip {
    font-size: $fontsizeParagraphsmall;
    font-weight: normal;
    padding: 0 8px;
    height: 20px;
    line-height: 20px;
  }

  ion-footer {
    &.fshadow {
      box-shadow: 0 0 12px rgba(var(--ion-color-dark-rgb),0.1);
    }
  }

  // Normalize font
  ion-modal,
  ion-slide,
  ion-item,
  ion-button,
  ion-tab-button {
    font-size: $fontsize;
  }
}

* {
  outline: none;
}

body {
  font-size: $fontsize;
  line-height: 1.5;
  background: #fff;
}

a {
  text-decoration: none;
}

ul, ol {
  padding-left: 20px;
}

hr {
  margin: 1.5em 0;
  background: rgba(var(--ion-color-dark-rgb),0.1);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}
h1,
.h1 {
  font-size: $fontsizeHeading1 !important;
  line-height: 50px;
}
h2,
.h2 {
  font-size: $fontsizeHeading2 !important;
}
h3,
.h3 {
  font-size: $fontsizeHeading3 !important;
}
h4,
.h4 {
  font-size: $fontsizeHeading4 !important;
}
h5,
.h5 {
  font-size: $fontsizeHeading5 !important;
}
h6,
.h6 {
  font-size: $fontsizeHeading6 !important;
}
p,
.p {
  font-size: $fontsizeParagraph !important;
}
.psmall {
  font-size: $fontsizeParagraphsmall !important;
}
.pxsmall {
  font-size: $fontsizeParagraphxsmall !important;
}
.pxxsmall {
  font-size: $fontsizeParagraphxxsmall !important;
}

ion-header {
  .title {
    display: flex;
    margin: 0 2px;
    align-items: center;
  }
  .icon-only {
    width: 36px;
    height: 36px !important;
    background: #DEDEDE;
    border-radius: 100%;
    margin: 0;
    svg {
      fill: var(--ion-color-primary);
      height: 16px;
      display: block;
    }
    &.clear {
      background: none;
      box-shadow: none;
    }
  }
  ion-button {
    margin: 0 !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    width: 36px;
    height: 36px !important;
    padding: 2px;
    svg {
      fill: var(--ion-color-secondary);
    }
  }

  .header-tabs {
    display: flex;
    overflow-x: scroll;
    .header-tab {
      flex: 0 0 90px;
      padding: 8px 10px;
      min-width: 0;
    }
  }
  .header-tab {
    cursor: pointer;
    text-align: center;
    padding: 8px 0;
    .header-tab-icon {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin: 0 auto;
      background: rgba(var(--ion-color-medium-rgb),0.1);
      margin-bottom: 4px;
      img {
        max-width: 24px;
        display: block;
      }
    }
    .header-tab-text {
      color: var(--ion-color-medium);
      font-size: $fontsizeParagraphxsmall;
    }
    &.active {
      .header-tab-icon {
        background: rgba(var(--ion-color-secondary-rgb),0.1);
      }
      .header-tab-text {
        color: var(--ion-color-primary);
        font-weight: bold;
      }
    }
  }
}

ion-slides {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

ion-modal {
  &.custom-modal {
    --width: 100%;
    .modal-shadow,
    .modal-wrapper {
      position: absolute;
      bottom: 0;
      border-radius: 1em 1em 0 0;
      box-shadow: 0 0 2em rgba(0,0,0,0.2);
      background: var(--ion-color-light) !important;
    }
    &.cm60 {
      --height: 60%;
    }
    &.cm80 {
      --height: 80%;
    }
  }
}

input,
ion-input {
  background: var(--ion-color-light) !important;
  height: 3em;
  border-radius: 1.5em !important;
  padding: 0 2em;
  width: 100%;
  --padding-start: 2em !important;
  --padding-end: 2em !important;
  color: #000 !important;
  border: none !important;
}

ion-button {
  height: 3em;
  font-weight: bold;
  border-radius: 1.5em;
  --border-radius: 1.5em;
  &.button-large {
    min-width: 20em;
    height: 4em;
    border-radius: 2em;
  --border-radius: 2em;
  }
  &.ion-color-primary {
    box-shadow: 0 0.5em 1em rgba(var(--ion-color-primary-rgb),0.4);
  }
}

ion-footer {
  border-radius: 1em 1em 0 0;
  box-shadow: 0 0 2em rgba(0,0,0,0.2);
  background: var(--ion-color-light) !important;
}

.back-button {
  padding: 1em;
  display: flex;
  align-items: center;
  ion-icon {
    margin-right: 0.5em;
  }
}

.category {
  border-radius: 1em;
  box-shadow: 0 0.5em 1em rgba(0,0,0,0.2);
  height: 100%;
  padding: 1em;
  text-align: center;
  color: var(--ion-color-primary);
  .category-icon {
    img {
      display: block;
      margin: 0 auto;
      height: 3em;
    }
  }
}

.photo {
  width: 20em;
  height: 20em;
  margin: 0 auto 4em;
  position: relative;
  .photo-video {
    width: 100% !important;
    height: 100% !important;
    background: #b9c5d2;
    border-radius: 4em;
    overflow: hidden;
    video {
      border: none;
      width: 100% !important;
      height: 100% !important;
    }
  }
  .photo-countdown {
    position: absolute;
    width: 5em;
    height: 5em;
    border-radius: 100%;
    bottom: 0;
    left: 50%;
    margin-bottom: -2.5em;
    margin-left: -2.5em;
    background: var(--ion-color-light);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 2em;
      color: var(--ion-color-secondary);
    }
  }
}

.ticket {
  height: 3.5em;
  max-width: 70%;
  margin: 2em auto;
  border-radius: 1em;
  box-shadow: 0 0.5em 1em rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  font-size: $fontsizeHeading1;
  font-weight: bold;
  color: var(--ion-color-secondary);
}

// Display
.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: rgba(255,255,255,0.06);
	width: 100vw;
	height: 11vh;
	display: flex;
	padding: 0 2vw;
	align-items: center;
	.logo {
		img {
			display: block;
			height: 9vh;
		}
	}
	.date-time {
		height: 100%;
		margin-left: auto;
		display: flex;
		align-items: center;
		.date {
			margin-right: 1vw;
		}
		.time {
		  font-weight: bold;
			color: var(--ion-color-secondary);
		  font-size: $fontsizeHeading4;
		}
	}
}
.content {
	.left,
	.center,
	.right {
	  position: fixed;
		top: 15vh;
		height: 75vh;
		overflow: auto;
	}
	.left,
	.center {
		width: 30vw;
		padding: 1vw 0;
		background: #fff;
		border-radius: 1em;
    box-shadow: 0 0.2em 0.6em rgba(0,0,0,0.05);
	}
	.left {
		left: 2vw;
	}
	.center {
		left: 34vw;
	}
	.right {
	  position: fixed;
		left: 66vw;
		width: 32vw;
		background: #000;
		ion-slides {
      height: 100%;
    }
    .swiper-slide {
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        display: block;
      }
    }
	}

	.q-card {
	  font-size: 32px;
	  padding: 0.75em 0.25em;
	  margin-bottom: 0.5em;
	  border-radius: 0.5em;
    color: var(--ion-color-primary);
    box-shadow: 0 0 0.4em rgba(0,0,0,0.1);
	  &.blue {
	    color: #fff;
	    background: var(--ion-color-primary);
	  }
	}
}
.running-text {
  height: 50px;
  overflow: hidden;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	width: 100vw;
	height: 6vh;
	display: flex;
	align-items: center;
	color: var(--ion-color-dark);
	.running-text-container {
    color: #252B41;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    align-items: center;
    display: flex;
		white-space: nowrap;
    /* Starting position */
    -moz-transform:translateX(100%);
    -webkit-transform:translateX(100%);
    transform:translateX(100%);
    /* Apply animation to this element */
    -moz-animation: example1 30s linear infinite;
    -webkit-animation: example1 30s linear infinite;
    animation: example1 30s linear infinite;
	}
}

/* Move it (define the animation) */
@-moz-keyframes example1 {
 0%   { -moz-transform: translateX(100%); }
 100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes example1 {
 0%   { -webkit-transform: translateX(100%); }
 100% { -webkit-transform: translateX(-100%); }
}
@keyframes example1 {
 0%   {
 -moz-transform: translateX(100%); /* Firefox bug fix */
 -webkit-transform: translateX(100%); /* Firefox bug fix */
 transform: translateX(100%);
 }
 100% {
 -moz-transform: translateX(-100%); /* Firefox bug fix */
 -webkit-transform: translateX(-100%); /* Firefox bug fix */
 transform: translateX(-100%);
 }
}


//Keyboard
.ui-keyboard {
  bottom: 0;
  padding: 2vw;
}
.ui-keyboard-preview-wrapper {
  height: 10vw;
  margin-bottom: 2vw;
  input {
    font-size: 3vw;
  }
}
.ui-keyboard-button {
  height: 8vw;
  width: 8vw;
  font-size: 3vw !important;
  margin: 0.4vw;
}
.ui-keyboard-space {
  width: 80%;
}
.ui-keyboard-accept {
  width: 15%;
}


@media screen and (max-width: 768px) {
  $fontsize: 30px;

  :root {
    // Normalize font
    ion-modal,
    ion-slide,
    ion-item,
    ion-button,
    ion-tab-button {
      font-size: $fontsize;
    }
  }

  body {
    font-size: $fontsize;
  }
}
